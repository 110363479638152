import React from "react";
import Button from "../../components/button/button";

function DetailedProduct(props) {
  return (
    <div className="product-details-grid">
      <div className="left-content-container">
        <img
          src={props.photo}
          className="product-detail-image"
          alt="Imagem do produto"
        />
      </div>
      <div className="right-content-container">
        <h2 className={props.color}>{props.name}</h2>
        <p className="normal-text mt-20">Categoria</p>
        <p className="medium-text mb-20">{props.category}</p>
        <p className="normal-text">Peso líquido</p>
        <p className="medium-text mb-20">{`${props.weight}`}</p>
        <p className="normal-text">Descrição</p>
        <p className="medium-text mb-30">{`${props.description}`}</p>
        
        <Button
          href={props.link}
          type="anchor"
          buttonStyle={props.buttonColor}
          buttonSize="btn--normal"
        >
          Comprar Online
        </Button>
      </div>
    </div>
  );
}

export default DetailedProduct;
