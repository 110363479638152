import React from "react";

function LandingHeader(props) {
  let typeBackground;
  switch (props.type) {
    case "green":
      typeBackground = "background-dark-green";
      break;
    case "blue":
      typeBackground = "background-dark-blue";
      break;
    default:
      typeBackground = "background-light-green";
      break;
  }

  const { title } = props;

  return (
    <div className="landing-header-container">
      <h3
        className={`landing-header white-text padding-top30 ${typeBackground}`}
      >
        {title}
      </h3>
    </div>
  );
}

export default LandingHeader;
