import React from "react";

function LeavesLandingRight() {
  return (
    <svg
      width="157"
      height="501"
      viewBox="0 0 157 501"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.699 499.912C63.5475 466.785 63.1785 442.953 59.0131 426.568C53.5116 404.909 40.2221 397.331 27.5197 393.401C17.421 390.28 6.99588 390.092 6.99588 390.092"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M6.99712 390.092C3.83079 405.653 2.40665 416.264 1.56304 426.749C0.611866 438.567 1.34496 452.467 3.28148 460.597C5.53111 470.053 8.48039 476.417 13.0248 482.256C18.9725 489.897 27.7868 494.135 35.2835 496.425C44.808 499.333 55.699 499.912 55.699 499.912"
        fill="#0093D5"
      />
      <path
        d="M119.826 288.354C126.824 276.246 134.536 259.958 133.442 244.798C132.783 235.649 127.981 229.711 122.769 225.504C119.352 222.746 115.17 220.85 115.17 220.85"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M115.168 220.85C111.547 226.811 109.17 232.193 107.413 236.008C105.932 239.224 103.041 247.733 102.138 252.82C101.411 256.916 101.201 262.355 101.522 265.349C102.248 272.087 105.726 277.362 108.098 279.969C113.421 285.822 119.825 288.353 119.825 288.353"
        fill="#2ED065"
      />
      <path
        d="M88.8817 306.754C85.5223 293.179 79.7766 276.097 68.472 265.937C61.6496 259.805 54.0723 258.86 47.3997 259.445C43.0252 259.829 38.6978 261.366 38.6978 261.366"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M38.6965 261.367C40.2246 268.172 42.2462 273.697 43.6269 277.664C44.7914 281.008 48.6124 289.142 51.4909 293.432C53.8091 296.886 57.4312 300.95 59.7396 302.882C64.9367 307.232 71.1011 308.618 74.6178 308.85C82.5122 309.373 88.8808 306.753 88.8808 306.753"
        fill="#2ED065"
      />
      <path
        d="M101.332 63.9307C104.836 50.392 107.908 32.6342 102.799 18.3194C99.7154 9.67988 93.5003 5.24359 87.3528 2.58379C83.3223 0.840532 78.785 0.133301 78.785 0.133301"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M78.7835 0.133272C76.8897 6.84644 76.0394 12.6678 75.3666 16.8138C74.8 20.3089 74.2907 29.2816 74.7816 34.4246C75.1773 38.5657 76.4301 43.8629 77.541 46.661C80.0431 52.959 84.8054 57.1115 87.7883 58.9886C94.4835 63.2039 101.331 63.9298 101.331 63.9298"
        fill="#2ED065"
      />
      <path
        d="M82.9153 225.042C85.4713 211.292 87.3048 193.364 81.2146 179.438C77.5394 171.034 71.0315 167.039 64.7143 164.812C60.5726 163.353 55.9971 162.962 55.9971 162.962"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M55.9957 162.962C54.572 169.79 54.1276 175.657 53.744 179.839C53.4213 183.365 53.5356 192.352 54.3821 197.448C55.0641 201.552 56.6813 206.749 57.9837 209.464C60.9166 215.573 65.9555 219.385 69.0614 221.051C76.0328 224.792 82.9146 225.041 82.9146 225.041"
        fill="#0093D5"
      />
      <path
        d="M90.9867 151.62C100.146 141.052 110.796 126.513 112.585 111.42C113.666 102.31 110.072 95.5729 105.749 90.4566C102.915 87.1028 99.1653 84.4514 99.1653 84.4514"
        fill="#008E3D"
      />
      <path
        d="M99.164 84.4507C94.4821 89.6212 91.1316 94.457 88.6851 97.8712C86.6233 100.75 82.1766 108.56 80.329 113.384C78.8417 117.269 77.6079 122.571 77.3585 125.571C76.7986 132.325 79.2172 138.162 81.0541 141.17C85.176 147.923 90.9866 151.619 90.9866 151.619"
        fill="#2ED065"
      />
      <path
        d="M92.6382 434.646C116.893 410.756 129.247 390.373 134.427 374.28C141.279 353.01 134.05 339.527 125.378 329.447C118.483 321.436 109.752 315.736 109.752 315.736"
        fill="#008E3D"
      />
      <path
        d="M109.753 315.736C98.8005 327.235 91.9544 335.466 85.6674 343.899C78.5804 353.404 71.8135 365.568 69.1328 373.484C66.0125 382.69 65.1286 389.648 65.8743 397.009C66.8514 406.643 72.0655 414.917 77.1987 420.841C83.7205 428.367 92.6383 434.646 92.6383 434.646"
        fill="#2ED065"
      />
    </svg>
  );
}

export default LeavesLandingRight;
