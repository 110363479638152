import React from "react";

function NoResults() {
  return (
    <div className="no-results-wrapper">
      <p className="large-text grey-text">Não foram encontrados resultados.</p>
    </div>
  );
}

export default NoResults;
