import React from "react";
import { Link } from "react-router-dom";

function HorizontalMenu() {
  return (
    <div className="horizontal-menu padding-top70">
      <span className="horizontal-menu-title">Compotas</span>
      <nav className="nav scroll">
        <Link to="/fig" className="nav-item fig-sidebar-link">
          <div className="sidebar-product-name">Figo Pingo de Mel</div>
        </Link>
        <Link to="/blueberry" className="nav-item blueberry-sidebar-link">
          <div className="sidebar-product-name">Mirtilo</div>
        </Link>
      </nav>
    </div>
  );
}

export default HorizontalMenu;
