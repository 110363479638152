import React from "react";

function CardIngredients(props) {
  return (
    <div className="card-ingredients">
      <div className="title">
        <p className="mb-10">Ingredientes</p>
      </div>
      <div className="ingredients-content">
        {props.firstIngredient && (
          <div className="first-ingredient">
            <p className="medium-text blue-text">{props.firstIngredient}</p>
          </div>
        )}
        <div className="sugar">
          <p className="medium-text blue-text">Açúcar</p>
        </div>
        <div className="lemon-juice">
          <p className="medium-text blue-text">Sumo de limão</p>
        </div>
        {props.extraIngredient && (
          <div className="extra-ingredient">
            <p className="medium-text blue-text">{props.extraIngredient}</p>
          </div>
        )}
        <div className="extra-info mt-20">
          <p className="medium-text dark-green-text">
            Não contém corantes nem conservantes.
          </p>
          <p className="medium-text dark-green-text">
            Sem glúten e sem lactose.
          </p>
        </div>
      </div>
    </div>
  );
}

/*
    <div className="card-ingredients">
      <div className="title">
        <p className="mb-10">Ingredientes</p>
      </div>
      <div className="ingredients-content">
        <p className="medium-text dark-blue-text">Farinha de Trigo</p>
        <p className="medium-text dark-blue-text">Cerveja 20%</p>
        <p className="medium-text dark-blue-text">Margarina Vegetal</p>
        <p className="medium-text dark-blue-text">Fermento</p>
        <p className="medium-text dark-blue-text">Açúcar</p>
        <p className="medium-text dark-blue-text">Sal</p>
      </div>
    </div>
  );
}
*/

export default CardIngredients;
