import React from "react";

function leavesBlueberry() {
  return (
    <svg
      width="111"
      height="327"
      viewBox="0 0 111 327"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98.36 62.7315C101.258 49.0504 103.538 31.1734 97.7969 17.1001C94.3323 8.60626 87.926 4.45077 81.6663 2.06701C77.5623 0.504736 72.998 0 72.998 0"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M72.9966 0C71.4032 6.79076 70.8126 12.6441 70.3249 16.8159C69.9143 20.3328 69.8046 29.3193 70.5237 34.4353C71.1032 38.5548 72.5903 43.791 73.8246 46.537C76.6043 52.7174 81.5466 56.654 84.61 58.3966C91.486 62.31 98.3595 62.7306 98.3595 62.7306"
        fill="#0093D5"
      />
      <path
        d="M60.8012 223.246C73.1444 216.672 88.3044 206.927 95.3949 193.483C99.6746 185.369 98.74 177.791 96.5424 171.463C95.1012 167.315 92.554 163.494 92.554 163.494"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M92.5531 163.493C86.3269 166.637 81.4633 169.947 77.954 172.255C74.9961 174.201 68.0415 179.894 64.5846 183.733C61.8014 186.825 58.7461 191.33 57.436 194.04C54.4882 200.143 54.6495 206.459 55.2837 209.926C56.7057 217.709 60.8016 223.244 60.8016 223.244"
        fill="#0093D5"
      />
      <path
        d="M65.7083 156.652C72.9488 123.697 72.1433 100.098 67.6785 83.9447C61.7813 62.5915 48.3552 55.3261 35.583 51.6645C25.4287 48.7564 15.002 48.7588 15.002 48.7588"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M15.0032 48.7588C12.1224 64.2298 10.8929 74.7658 10.2414 85.166C9.50687 96.8891 10.4944 110.644 12.5795 118.662C15.002 127.987 18.0673 134.237 22.7179 139.938C28.8045 147.398 37.695 151.436 45.2324 153.568C54.8086 156.276 65.7083 156.652 65.7083 156.652"
        fill="#0093D5"
      />
      <path
        d="M55.7083 326.893C62.9488 293.938 62.1433 270.34 57.6785 254.186C51.7813 232.833 38.3552 225.567 25.583 221.906C15.4287 218.998 5.00195 219 5.00195 219"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M5.00321 219C2.12243 234.471 0.892875 245.007 0.24143 255.407C-0.493126 267.13 0.49443 280.885 2.57954 288.903C5.00199 298.228 8.06732 304.478 12.7179 310.179C18.8045 317.64 27.695 321.677 35.2324 323.809C44.8086 326.517 55.7083 326.893 55.7083 326.893"
        fill="#0093D5"
      />
    </svg>
  );
}

export default leavesBlueberry;
