import React from "react";

function CardNutritional(props) {
  return (
    <div className="card-nutritional">
      <div className="title">
        <p className="mb-10">Valores nutricionais (100g)</p>
      </div>
      <div className="nutritional-content">
        <div className="energy">
          <span>Energia</span>
          <span> {props.energy}</span>
        </div>
        <div className="lipids">
          <span>Lípidos</span>
          <span> {props.lipids}</span>
          &nbsp;
          <span className="medium-text">{`(saturados: ${props.saturados})`}</span>
        </div>
        <div className="hydrates">
          <span>Hidratos de Carbono</span>
          <span> {props.hydrates}</span>
          &nbsp;
          <span className="medium-text">{`(açúcares: ${props.sugars})`}</span>
        </div>
        <div className="fiber">
          <span>Fibra</span>
          <span> {props.fiber}</span>
        </div>
        <div className="proteins">
          <span>Proteínas</span>
          <span> {props.proteins}</span>
        </div>
        <div className="salt">
          <span>Sal</span>
          <span> {props.salt}</span>
        </div>
      </div>
    </div>
  );
}
export default CardNutritional;
