import React from "react";
import { Link } from "react-router-dom";

import Product from "../components/product/generalProduct";
import NoResults from "../components/noResults/noResults";
import FilterDropdown from "../components/filterDropdown/filterDropdown";

import fig from "../images/products/fig.png";

function SearchResults() {
  const results = [
    {
      name: "Figo Pingo de mel",
      photo: fig,
      link: "/fig",
      className: "fig-link",
      category: "Compotas",
    },
  ];

  const searchedProduct = "figo";

  const Results = () => (
    <p className="medium-text mt-20 mb-50 dark-blue-text">
      Pesquisou por {` "${searchedProduct}" `}
    </p>
  );

  if (results.length === 0) {
    return (
      <div className="no-results-page">
        <Link to="/">
          <span className="normal-text mr-10">
            <i className="fa fa-arrow-left"></i>
          </span>
          <span className="small-text dark-blue-text">Voltar</span>
        </Link>
        <h1 className="dark-blue-text mt-10">Resultados</h1>
        <NoResults />;
      </div>
    );
  }

  return (
    <div className="search-results-page">
      <Link to="/">
        <span className="normal-text mr-10">
          <i className="fa fa-arrow-left"></i>
        </span>
        <span className="small-text dark-blue-text">Voltar</span>
      </Link>
      <FilterDropdown />
      <h1 className="dark-blue-text mt-10">Resultados</h1>
      <Results />
      <div className="product-list-grid mb-100">
        {results.map((result, idx) => (
          <Product key={idx} product={result} />
        ))}
      </div>
    </div>
  );
}

export default SearchResults;
