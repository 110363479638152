import React from "react";
import AboutSection from "../components/aboutSection/aboutSection";
import Flavours from "../components/product/flavours";
import CardLogo from "../components/card/cardLogo";

import LeavesAbout from "../components/leaves/leavesAbout";
import physalina from "../images/logos/physalina-logo-slogan.svg";
//import qpetisco from "../images/logos/qpetisco-logo-slogan.svg";

function About() {
  return (
    <div className="about-page">
      <div className="header-wrapper" data-aos="fade-in">
        <h1 className="title dark-blue-text">Sobre nós</h1>
        <p className="large-text dark-blue-text">
          A Floryptus foi criada há alguns anos com o objetivo de melhorar a
          gestão de algumas propriedades agroflorestais de cariz familiar,
          principalmente situadas na região de Águeda.
        </p>
      </div>
      <div className="leaves-wrapper">
        <div className="about-left-leaves">
          <LeavesAbout />
        </div>
        <div className="about-right-leaves">
          <LeavesAbout />
        </div>
      </div>
      <div className="about-wrapper">
        <div
          className="our-proccess"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="750"
        >
          <AboutSection
            backgroundColor="background-light-green"
            title="O nosso processo"
            subtitle="Sabe como fabricamos produtos de elevada qualidade?"
            text="Gradualmente vamos criando novos produtos, doces, compotas e geleias, sempre obtidos através da adição de matérias primas naturais, tais como; ervas aromáticas, frutos,... mas nunca através da adição de essências sintetizadas laboratorialmente. Através dos nossos métodos de produção artesanais, conseguimos obter os nossos produtos livres de corantes e conservantes artificiais."
          ></AboutSection>
        </div>
        <div
          className="our-story"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <AboutSection
            backgroundColor="background-dark-green"
            title="Sobre a marca"
            subtitle="Quer-nos conhecer melhor?"
            text="A Floryptus, Lda., uma pequena empresa, foi criada há alguns anos com o objetivo de melhorar a gestão de algumas propriedades agroflorestais de cariz familiar, situadas na região de Águeda. Os produtos fabricados pela Floryptus, Lda, tendo a fruta como matéria prima principal, contêm sempre, quer sejam Doces, Compotas, Geleias, etc., um teor mínimo de 60% de fruta."
          ></AboutSection>
        </div>
      </div>
      <div className="discover-our-flavours">
        <Flavours />
      </div>
      <div className="discover-our-brands">
        <h3 className="dark-blue-text">Já conhece a nossa outra marca?</h3>
        <div
          className="cards-our-brands-wrapper"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <CardLogo
            alt="Physalina"
            src={physalina}
            link="https://www.physalina.pt/"
            className="physalina-logo"
          ></CardLogo>
          {/*<CardLogo
            alt="Q'Petisco"
            src={qpetisco}
            className="qpetisco-logo"
          ></CardLogo>*/}
        </div>
      </div>
    </div>
  );
}

export default About;
