import React from "react";

function CardLogo(props) {
  return (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      className={props.className}
    >
      <div className="logo-card">
        <img alt={props.alt} src={props.src} />
      </div>
    </a>
  );
}
export default CardLogo;
