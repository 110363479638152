import React from "react";
import Product from "../components/product/generalProduct";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
//import FilterDropdown from "../components/filterDropdown/filterDropdown";

//import product images
import fig from "../images/products/fig.png";
import blueberry from "../images/products/blueberry.png";
import yellow from "../images/products/yellow.png";
import red from "../images/products/red.png";

function ProductList() {
  const products = [
    {
      name: "Figo Pingo de Mel",
      photo: fig,
      link: "/fig",
      className: "fig-link",
      buttonLink: "/contacts",
      buttonColor: "btn--fig"
    },
    {
      name: "Mirtilo",
      photo: blueberry,
      link: "/blueberry",
      className: "blueberry-link",
      buttonLink: "/contacts",
      buttonColor: "btn--blueberry"
    },
    {
      name: "Figo da Índia Amarelo",
      photo: yellow,
      link: "/yellowFig",
      className: "fig-link",
      buttonLink: "/contacts",
      buttonColor: "btn--fig"
    },
    {
      name: "Figo da Índia Vermelho",
      photo: red,
      link: "/redFig",
      className: "blueberry-link",
      buttonLink: "/contacts",
      buttonColor: "btn--blueberry"
    },
  ];

  return (
    <div className="product-list-page">
      <Breadcrumb home="Home" section="Compotas"></Breadcrumb>
      {/*<FilterDropdown />*/}
      <div className="title-subtitle">
        <h1 className="dark-blue-text mt-10 mb-10">Produtos</h1>
        <p className="grey-text large-text padding-bottom30">
          As nossas compotas e os nossos doces <br />são 100% produzidos em Portugal.
        </p>
      </div>
      <div className="product-list-grid">
        {products.map((product, idx) => (
          <Product key={idx} product={product} />
        ))}
      </div>
    </div>
  );
}

export default ProductList;
