import React from "react";
import { Link } from "react-router-dom";

function CardProduct(props) {
  const { product } = props;

  return (
    <Link to={product.link}>
      <div className="card-product" data-aos="fade-up" data-aos-duration="1000">
        <div className="card-product-name">
          <span className="large-text dark-blue-text mr-10">
            {product.name}
          </span>
          <span className="small-text dark-blue-text">
            <i className="fa fa-chevron-right"></i>
          </span>
        </div>
        <div className="card-product-layout">
          <div className="card-product-image">
            <img
              className="resized-product-image"
              src={product.image}
              alt={product.alt}
            />
          </div>
          <div className="card-product-info">
            <p className="small-text dark-blue-text mt-10">
              <b>Categoria</b>
            </p>
            <p className="medium-text dark-blue-text">{product.category}</p>
            <p className="small-text dark-blue-text mt-20">{product.text}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CardProduct;
