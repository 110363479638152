import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb(props) {
  return (
    <ul className="breadcrumb">
      <li>
        <Link to="/">{props.home}</Link>
      </li>
      <li>
        <Link to="/jams">{props.section}</Link>
      </li>
      {props.name && <li>{props.name}</li>}
    </ul>
  );
}
export default Breadcrumb;
