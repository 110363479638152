import React from "react";

function LeavesLandingLeft() {
  return (
    <svg
      width="187"
      height="574"
      viewBox="0 0 187 574"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M131.318 571.244C153.18 545.147 163.53 523.677 167.149 507.162C171.937 485.335 163.453 472.604 153.859 463.399C146.229 456.083 136.994 451.243 136.994 451.243"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M136.995 451.244C127.191 463.736 121.163 472.584 115.71 481.579C109.564 491.717 103.99 504.472 102.077 512.608C99.8509 522.07 99.6357 529.08 101.081 536.337C102.974 545.833 108.955 553.571 114.631 558.978C121.842 565.846 131.318 571.244 131.318 571.244"
        fill="#0093D5"
      />
      <path
        d="M99.6939 486.132C107.678 453.037 107.407 429.204 103.309 412.802C97.8966 391.12 84.6383 383.487 71.9521 379.506C61.8663 376.343 51.4421 376.112 51.4421 376.112"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M51.4434 376.112C48.2132 391.661 46.7455 402.266 45.8588 412.747C44.8591 424.561 45.5352 438.464 47.4383 446.602C49.6491 456.067 52.5722 462.443 57.0926 468.301C63.0089 475.966 71.8057 480.24 79.293 482.56C88.8054 485.508 99.6939 486.132 99.6939 486.132"
        fill="#2ED065"
      />
      <path
        d="M132.19 421.642C142.248 411.925 154.141 398.385 157.258 383.509C159.139 374.53 156.155 367.502 152.301 362.023C149.774 358.432 146.274 355.459 146.274 355.459"
        fill="#008E3D"
      />
      <path
        d="M146.273 355.459C141.152 360.195 137.387 364.716 134.648 367.9C132.34 370.585 127.221 377.972 124.954 382.614C123.129 386.352 121.431 391.524 120.918 394.491C119.763 401.168 121.656 407.197 123.22 410.355C126.729 417.446 132.19 421.641 132.19 421.641"
        fill="#2ED065"
      />
      <path
        d="M71.4377 521.528C63.2942 510.159 51.6367 496.416 37.3754 491.159C28.7684 487.986 21.3791 489.911 15.3964 492.923C11.4745 494.899 8.02264 497.927 8.02264 497.927"
        fill="#008E3D"
      />
      <path
        d="M8.02164 497.928C11.9592 503.686 15.8814 508.071 18.6317 511.245C20.9506 513.921 27.5099 520.065 31.7715 522.985C35.2031 525.337 40.0714 527.772 42.9311 528.713C49.3684 530.831 55.6085 529.839 58.9615 528.753C66.489 526.318 71.4366 521.528 71.4366 521.528"
        fill="#2ED065"
      />
      <path
        d="M89.6537 365.354C96.6518 353.246 104.364 336.958 103.27 321.798C102.611 312.649 97.8092 306.711 92.5975 302.504C89.1804 299.746 84.9977 297.85 84.9977 297.85"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M84.9963 297.85C81.3754 303.811 78.9986 309.193 77.2411 313.008C75.76 316.224 72.8686 324.733 71.9657 329.82C71.2389 333.916 71.0288 339.355 71.3506 342.349C72.0764 349.087 75.5541 354.362 77.9261 356.969C83.2493 362.822 89.6534 365.353 89.6534 365.353"
        fill="#0093D5"
      />
      <path
        d="M58.7434 291.042C61.2994 277.292 63.133 259.364 57.0427 245.438C53.3675 237.034 46.8596 233.039 40.5424 230.812C36.4007 229.353 31.8253 228.962 31.8253 228.962"
        fill="#008E3D"
      />
      <path
        d="M31.8238 228.962C30.4002 235.79 29.9557 241.657 29.5722 245.839C29.2494 249.365 29.3638 258.352 30.2102 263.448C30.8922 267.552 32.5094 272.749 33.8118 275.464C36.7447 281.573 41.7836 285.385 44.8895 287.051C51.861 290.792 58.7427 291.041 58.7427 291.041"
        fill="#2ED065"
      />
      <path
        d="M64.8256 216.354C71.8237 204.246 79.5356 187.958 78.4422 172.798C77.7827 163.649 72.9811 157.711 67.7694 153.504C64.3522 150.746 60.1696 148.85 60.1696 148.85"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M60.1682 148.85C56.5472 154.811 54.1704 160.193 52.4129 164.008C50.9319 167.224 48.0405 175.733 47.1375 180.82C46.4108 184.916 46.2007 190.355 46.5224 193.349C47.2483 200.087 50.726 205.362 53.0979 207.969C58.4212 213.822 64.8253 216.353 64.8253 216.353"
        fill="#2ED065"
      />
      <path
        d="M43.9153 142.042C46.4713 128.292 48.3048 110.364 42.2146 96.4384C38.5394 88.0336 32.0315 84.0391 25.7143 81.8122C21.5726 80.3527 16.9971 79.9619 16.9971 79.9619"
        fill="#0093D5"
        fillOpacity="0.7"
      />
      <path
        d="M16.9957 79.9619C15.572 86.7903 15.1276 92.6566 14.744 96.8392C14.4213 100.365 14.5356 109.352 15.3821 114.448C16.0641 118.552 17.6813 123.749 18.9837 126.464C21.9166 132.573 26.9555 136.385 30.0614 138.051C37.0328 141.792 43.9146 142.041 43.9146 142.041"
        fill="#0093D5"
      />
      <path
        d="M41.9867 68.6197C51.1459 58.0518 61.7956 43.5134 63.5855 28.4198C64.6661 19.3104 61.0725 12.5729 56.7493 7.45663C53.9147 4.10277 50.1653 1.45142 50.1653 1.45142"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M50.164 1.45074C45.4821 6.6212 42.1316 11.457 39.6851 14.8712C37.6233 17.7496 33.1766 25.5597 31.329 30.3843C29.8417 34.2693 28.6079 39.571 28.3585 42.5713C27.7986 49.3249 30.2172 55.1621 32.0541 58.1699C36.176 64.923 41.9866 68.6187 41.9866 68.6187"
        fill="#2ED065"
      />
      <path
        d="M43.6375 354.366C42.8938 340.401 40.4666 322.543 31.2769 310.437C25.731 303.13 18.4671 300.775 11.8036 300.094C7.43505 299.647 2.89575 300.342 2.89575 300.342"
        fill="#008E3D"
      />
      <path
        d="M2.89437 300.342C3.11383 307.314 4.05919 313.121 4.66843 317.276C5.18258 320.78 7.40375 329.488 9.4232 334.243C11.0496 338.072 13.842 342.745 15.7453 345.077C20.0307 350.327 25.8238 352.85 29.2339 353.739C36.8888 355.739 43.6367 354.365 43.6367 354.365"
        fill="#2ED065"
      />
    </svg>
  );
}

export default LeavesLandingLeft;
