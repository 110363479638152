import React from "react";

import Breadcrumb from "../components/breadcrumb/breadcrumb";
import Sidebar from "../components/sidebar/sidebar";
import HorizontalMenu from "../components/sidebar/horizontalMenu";
import DetailedProduct from "../components/product/detailedProduct";
import LeavesFig from "../components/leaves/leavesFig";
import CardIngredients from "../components/card/cardIngredients";
import CardNutritional from "../components/card/cardNutritional";
//import SuggestedProductsHeader from "../components/product/suggestedProductsHeader";
//import DiscoverProduct from "../components/product/discoverProduct";

//import product's images
import yellow from "../images/products/yellow.png";
//import eucalyptus from "../images/products/eucalipto.png";

function Fig() {
  /*const suggestions = [
    {
      name: "Doce de Physalis com Eucalipto",
      photo: eucalyptus,
      link: "https://www.physalina.pt/eucalipto",
      className: "physalina-link",
    },
  ];*/

  return (
    <div className="product-details-page">
      <div className="horizontal-menu">
        <HorizontalMenu />
      </div>
      <div className="breadcrumb-container">
        <Breadcrumb
          home="Home"
          section="Compotas"
          name="Figo da Índia Amarelo"
        ></Breadcrumb>
      </div>
      <div className="product-details-grid">
        <div className="sidebar-menu padding-top30 padding-left50">
          <Sidebar />
        </div>
        <DetailedProduct
          photo={yellow}
          name="Figo da Índia Amarelo"
          color="light-green-text"
          category="Doce Extra"
          weight="240g"
          description="Doce produzido a partir da polpa de Figos da Índia amarelos com aspeto muito cremoso, de tom sobre o amarelado. Doce de textura muito cremosa e aveludada na boca, de sabor muito próprio e caraterístico do Figo da Índia. "
          link="/contacts"
          buttonColor="btn--primary"
        ></DetailedProduct>
        <div className="details">
          <h2 className="dark-blue-text ml-10">Detalhes</h2>
          <div className="cards">
            <div className="card-ingridients-container">
              <CardIngredients firstIngredient="Figos da Índia amarelos" extraIngredient="Pectina de Frutas e Sal"></CardIngredients>
            </div>
            <div className="card-nutritional-container">
              <CardNutritional
                energy="1106 kj / 260 kcal"
                lipids="0.29 g"
                saturados="0.00 g"
                hydrates="63.49 g"
                sugars="52.02 g"
                fiber="0.21 g"
                proteins="0.82 g"
                salt="0.35 g"
              ></CardNutritional>
            </div>
          </div>
        </div>
        <div
          className="leaves-wrapper left-leaves"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <LeavesFig />
        </div>
        <div
          className="leaves-wrapper right-leaves"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <LeavesFig />
        </div>
        <div
          className="suggestions-heading"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-easing="ease-in"
        >
        </div>
      </div>
    </div>
  );
}

export default Fig;
