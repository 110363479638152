import React from "react";
import { Link } from "react-router-dom";

import logo from "../../images/logos/floryptus-logo-slogan.svg";
import physalinaLogo from "../../images/logos/physalina-logo.svg";
//import qpetiscoLogo from "../../images/logos/qpetisco-logo.svg";

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-section logo">
          <img src={logo} alt="FloryptusLogo" id="floryptus-logo" />
        </div>
        <div className="footer-section contacts">
          <p className="normal-text blue-text mb-10">Saiba onde estamos</p>
          <span className="small-text blue-text">
            <i className="fa fa-map-marker mr-10"></i>
            Rua Herculano Coelho, 18 <br />
            3080-847 Figueira da Foz, Portugal
          </span>
        </div>
        <div className="footer-section links">
          <Link to="/contacts" className="small-text">
            <i className="fa fa-address-book mr-10"></i>
            Contactos
          </Link>
          <Link to="/termsConditions" className="small-text">
            <i className="fa fa-sticky-note mr-10"></i>
            Termos e condições
          </Link>
          <Link to="/about" className="small-text">
            <i className="fa fa-envira mr-10"></i>
            Sobre nós
          </Link>
        </div>
        <div className="footer-section brands">
          <p className="normal-text grey-text">Descubra a nossa marca</p>
          <div className="mb-20">
            <a
              href="https://www.physalina.pt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={physalinaLogo} alt="PhysalinaLogo" />
            </a>
          </div>
          {/*
          <img
            src={qpetiscoLogo}
            alt="Q'PetiscoLogo"
            className="qpetisco-logo"
          />*/}
        </div>
      </div>
      <div className="footer-bottom small-text">
        <p>&copy; 2021 Floryptus, Lda. All rights reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
