import React from "react";

function SuggestedProductsHeader(props) {
  return (
    <section>
      <h3 className="dark-blue-text">{props.question}</h3>
      <div className={`color-header mt-20 ${props.backgroundColor}`}>
        <h2 className={`header-title ${props.textColor}`}>{props.answer}</h2>
      </div>
    </section>
  );
}

export default SuggestedProductsHeader;
