import React, { useState } from "react";
import Contact from "../components/contact/contact";
import Input from "../components/input/input";
import Textarea from "../components/textarea/textarea";
import Button from "../components/button/button";
import Explore from "../components/explore/explore";
import LeavesContacts from "../components/leaves/leavesContacts";

function About() {
  const contacts = [
    {
      sentence: "Entre em contacto connosco",
      contact: "(+351) 937 080 911",
    },
    {
      sentence: "Envie-nos um e-mail",
      contact: "info@floryptus.pt",
    },
    {
      sentence: "Saiba onde estamos",
      contact: "Rua Herculano Coelho, nº18 3080-847 Figueira da Foz",
    },
    {
      sentence: "Siga-nos nas redes sociais",
      icon: "fa fa-facebook-square",
      link: "https://www.facebook.com/physalina.pt/",
    },
  ];

  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  return (
    <div className="contacts-page">
      <div className="headings-wrapper-contacts">
        <h1 className="dark-blue-text">Contactos</h1>
        <p className="large-text mt-10 grey-text">
          Estamos ao seu dispor para prestar informações
          <br /> e esclarecer dúvidas.
        </p>
      </div>
      <div className="contacts-grid">
        <div className="brand-contacts">
          {contacts.map((contacts, idx) => (
            <Contact key={idx} contacts={contacts} />
          ))}
        </div>
        <div className="contact-card">
          <p className="large-text dark-blue-text mb-30">
            Tem alguma dúvida ou sugestão?
          </p>
          <div className="contact-card-item">
            <Input
              id="subject"
              labelInput="Assunto"
              placeholder="A que diz respeito?"
              styleName="input-width"
              onChange={setSubject}
            ></Input>
          </div>
          <div className="contact-card-item">
            <Textarea
              id="message"
              labelTextarea="Mensagem"
              placeholder="Exponha aqui o seu comentário"
              styleName="textarea-size"
              onChange={setMessage}
            ></Textarea>
          </div>
          <div className="contact-card-button">
            <Button
              type="anchor"
              buttonStyle="btn--primary"
              buttonSize="btn--large"
              href={`mailto:info@floryptus.pt?subject=${subject}&body=${message}`}
            >
              Enviar
            </Button>
          </div>
        </div>
      </div>
      <div className="leaves-contacts-wrapper">
        <div className="leaves-contacts">
          <LeavesContacts />
        </div>
      </div>
      <Explore text="Explore os nossos produtos" link="/jams"></Explore>
    </div>
  );
}

export default About;
