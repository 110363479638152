import React from "react";

import figFlavour from "../../images/flavours/fig-flavour.png";
import blueberryFlavour from "../../images/flavours/blueberry-flavour.png";

function Flavours(props) {
  return (
    <section className="flavours-container">
      <h2 className="header dark-blue-text">Descubra os nossos sabores</h2>
      <div className="flavours-content">
        <div className="flavours-wrapper">
          <div className="fig-flavour" data-aos="zoom-in-down">
            <div className="image-wrapper">
              <img src={figFlavour} alt="Sabor a figo" />
            </div>
            <div className="flavour-name-wrapper">
              <svg
                width="32"
                height="63"
                viewBox="0 0 32 63"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.36 62.7315C31.258 49.0504 33.538 31.1734 27.7969 17.1001C24.3323 8.60626 17.926 4.45077 11.6663 2.06701C7.56233 0.504736 2.99805 0 2.99805 0"
                  fill="#2ED065"
                  fillOpacity="0.7"
                />
                <path
                  d="M2.99659 0C1.40316 6.79076 0.812592 12.6441 0.324878 16.8159C-0.0856937 20.3328 -0.195408 29.3193 0.523735 34.4353C1.10316 38.5548 2.59031 43.791 3.82459 46.537C6.60431 52.7174 11.5466 56.654 14.61 58.3966C21.486 62.31 28.3595 62.7306 28.3595 62.7306"
                  fill="#2ED065"
                />
              </svg>
              <h3 className="dark-blue-text">Figo Pingo de Mel</h3>
            </div>
          </div>
          <div className="blueberry-flavour" data-aos="zoom-in-down">
            <div className="image-wrapper">
              <img src={blueberryFlavour} alt="Sabor a mirtilo" />
            </div>
            <div className="flavour-name-wrapper">
              <svg
                width="32"
                height="63"
                viewBox="0 0 32 63"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.36 62.7315C31.258 49.0504 33.538 31.1734 27.7969 17.1001C24.3323 8.60626 17.926 4.45077 11.6663 2.06701C7.56233 0.504736 2.99805 0 2.99805 0"
                  fill="#0093D5"
                  fillOpacity="0.7"
                />
                <path
                  d="M2.99659 0C1.40316 6.79076 0.812592 12.6441 0.324878 16.8159C-0.0856937 20.3328 -0.195408 29.3193 0.523735 34.4353C1.10316 38.5548 2.59031 43.791 3.82459 46.537C6.60431 52.7174 11.5466 56.654 14.61 58.3966C21.486 62.31 28.3595 62.7306 28.3595 62.7306"
                  fill="#0093D5"
                />
              </svg>
              <h3 className="dark-blue-text">Mirtilo</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Flavours;
