import React from "react";
import { Link } from "react-router-dom";

function Explore(props) {
  return (
    <div className="explore-section">
      <Link to={props.link} className="link">
        <span className="h3 white-text">{props.text}</span>
        <i className="fa fa-chevron-right"></i>
      </Link>
    </div>
  );
}
export default Explore;
