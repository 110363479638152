import React from "react";
//import { Link } from "react-router-dom";

function DiscoverProduct(props) {
  const { discoverProduct } = props;

  return (
    <div className="discover-product">
      <a href={discoverProduct.link} target="_blank" rel="noopener noreferrer" className={discoverProduct.className}>
        <img
          className="discover-product-image"
          src={discoverProduct.photo}
          alt="Produto sugerido"
        />
        <br></br>
        <div className="discover-product-name">
          <p>{discoverProduct.name}</p>
        </div>
        <div className="image-hover"></div>
      </a>
    </div>
  );
}

export default DiscoverProduct;
