import React from "react";

function TermsConditions() {
  return (
    <div className="terms-conditions-wrapper">
      <section className="terms-conditions">
        <h3 className="mb-20">Termos e Condições de Utilização</h3>
        <p className="small-text">
          A FLORYPTUS, LDA., empresa sedeada na Figueira da Foz detém o domínio
          do site FLORYPTUS, sendo por isso responsável pela sua exploração e
          gestão de operação. Para os utilizadores que procedam à consulta e
          utilização deste site implica, desde já, a aceitação dos presentes
          Termos e Condições de Utilização (“TCU”).
        </p>
        <h3>Acesso ao site</h3>
        <p className="small-text">
          Na qualidade de proprietária do site, a FLORYPTUS, LDA. poderá, a
          qualquer momento, vedar o seu acesso a terceiros, designadamente para
          efeitos de reparação ou manutenção, para além de ser titular do
          direito de o encerrar caso ocorra qualquer circunstância que, em seu
          entender, o justifique.
        </p>
        <h3>Direitos de propriedade intelectual</h3>
        <p className="small-text">
          Na qualidade de proprietária do site, a FLORYPTUS, LDA. é titular dos
          direitos de autor sobre todos os textos, desenhos, imagens, gráficos e
          demais conteúdo que estejam ali dispostos. Nessa conformidade, os
          utilizadores não estão autorizados a reproduzir, copiar, alterar,
          vender ou utilizar estes elementos sem obterem, previamente, por
          escrito a necessária autorização por parte da FLORYPTUS, LDA.
        </p>
        <h3>Privacidade e tratamento de dados pessoais</h3>
        <p className="small-text">
          Neste momento o site FLORYPTUS não solicita aos seus utilizadores a
          indicação de um contacto de e-mail, de uma senha de acesso ou qualquer
          outro dado pessoal, uma vez que não está operacional o registo para
          efeitos de criação de conta de utilizador. Uma vez que aos
          utilizadores do site não lhes é exigido qualquer dado pessoal, não se
          coloca aqui a necessidade e como tal a responsabilidade dos
          utilizadores manterem a atualização daqueles elementos. Por essa
          razão, pelo menos por ora, não se encontra justificada a necessidade
          da FLORYPTUS, LDA. aprovar qualquer Política de Privacidade onde sejam
          definidos os princípios gerais aplicáveis em matéria de recolha e
          tratamento de dados pessoais aos quais se daria estrito cumprimento.
        </p>
        <h3>Links de terceiros</h3>
        <p className="small-text">
          O site disponibiliza, através de hiperligação, o acesso a Websites de
          entidades terceiras. Contudo, como tais Websites não são controlados
          ou operados pela FLORYPTUS, LDA., os respetivos termos e condições de
          utilização não lhes é aplicável, razão pela qual não se responsabiliza
          pelo seu conteúdo ou teor dos termos e condições de utilização
          (Websites de terceiros).
        </p>
        <h3>Alterações aos TCU</h3>
        <p className="small-text">
          A FLORYPTUS, LDA., reserva-se no direito de proceder às alterações que
          se revelarem necessárias nestes TCU. Sempre que tal aconteça, as
          mesmas serão publicadas no site, sendo recomendável que a sua consulta
          seja efectuada pelos utilizadores de forma regular.
        </p>
        <h3>Sugestões e reclamações</h3>
        <p className="small-text">
          Caso surja aos utilizadores qualquer questão relacionada com os
          presentes TCU, poderá ser utilizado o seguinte contacto:
          j.a.maia@floryptus.pt
        </p>
        <h3>Política de cookies</h3>
        <p className="small-text mb-100">
          Caso surja aos utilizadores qualquer questão relacionada com os
          presentes TCU, poderá ser utilizado o seguinte contacto:
          j.a.maia@floryptus.pt
        </p>
      </section>
    </div>
  );
}

export default TermsConditions;
