import React from "react";
import ExternalLink from "../../components/externalLink/externalLink";

function Contact(props) {
  const { contacts } = props;

  return (
    <div className="single-contact-component">
      <p className="medium-text dark-blue-text">{contacts.sentence}</p>
      {contacts.contact && (
        <h3 className="dark-blue-text">{contacts.contact}</h3>
      )}
      {contacts.icon && (
        <ExternalLink link={contacts.link}>
          <i className={`contact-icon ${contacts.icon}`}></i>
        </ExternalLink>
      )}
    </div>
  );
}

export default Contact;
