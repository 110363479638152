import React from "react";

function Input(props) {
  return (
    <div className="input">
      <p className="label-textfield">{props.labelInput}</p>
      <input
        type="text"
        placeholder={props.placeholder}
        className={props.styleName}
        id={props.id}
        onChange={event => props.onChange(event.target.value)}
      />
    </div>
  );
}
export default Input;
