import React from "react";

import Breadcrumb from "../components/breadcrumb/breadcrumb";
import Sidebar from "../components/sidebar/sidebar";
import HorizontalMenu from "../components/sidebar/horizontalMenu";
import DetailedProduct from "../components/product/detailedProduct";
import LeavesFig from "../components/leaves/leavesFig";
import CardIngredients from "../components/card/cardIngredients";
import CardNutritional from "../components/card/cardNutritional";
import SuggestedProductsHeader from "../components/product/suggestedProductsHeader";
import DiscoverProduct from "../components/product/discoverProduct";

//import product's images
import fig from "../images/products/fig.png";
import eucalyptus from "../images/products/eucalipto.png";

function Fig() {
  const suggestions = [
    {
      name: "Doce de Physalis com Eucalipto",
      photo: eucalyptus,
      link: "https://www.physalina.pt/eucalipto",
      className: "physalina-link",
    },
  ];

  return (
    <div className="product-details-page">
      <div className="horizontal-menu">
        <HorizontalMenu />
      </div>
      <div className="breadcrumb-container">
        <Breadcrumb
          home="Home"
          section="Compotas"
          name="Figo Pingo de Mel"
        ></Breadcrumb>
      </div>
      <div className="product-details-grid">
        <div className="sidebar-menu padding-top30 padding-left50">
          <Sidebar />
        </div>
        <DetailedProduct
          photo={fig}
          name="Figo Pingo de Mel"
          color="light-green-text"
          category="Compotas"
          weight="240g"
          description="Um sabor e textura muito próprios e característicos do Figo Pingo de Mel, tornam esta compota excelente para usar nas mais diversas utilizações, desde a acompanhar queijos até uma simples torrada, ou a rechear bolos, tartes e tudo o que se deseje, pois com tudo condiz."
          link="/contacts"
          buttonColor="btn--primary"
        ></DetailedProduct>
        <div className="details">
          <h2 className="dark-blue-text ml-10">Detalhes</h2>
          <div className="cards">
            <div className="card-ingridients-container">
              <CardIngredients firstIngredient="Figos Pingo de Mel (>60%)"></CardIngredients>
            </div>
            <div className="card-nutritional-container">
              <CardNutritional
                energy="913 kj / 218 kcal"
                lipids="0.26 g"
                saturados="0.00 g"
                hydrates="52.67 g"
                sugars="39.91 g"
                fiber="1.75 g"
                proteins="0.64 g"
                salt="0.31 g"
              ></CardNutritional>
            </div>
          </div>
        </div>
        <div
          className="leaves-wrapper left-leaves"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <LeavesFig />
        </div>
        <div
          className="leaves-wrapper right-leaves"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <LeavesFig />
        </div>
        <div
          className="suggestions-heading"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-easing="ease-in"
        >
          <SuggestedProductsHeader
            question="Quer mergulhar nos aromas da floresta?"
            backgroundColor="background-light-green"
            answer="Aproveite!"
            textColor="white-text"
          ></SuggestedProductsHeader>
        </div>
        <div className="discover-section-grid">
          {suggestions.map((discoverProduct, idx) => (
            <DiscoverProduct key={idx} discoverProduct={discoverProduct} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Fig;
