import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/button";

function Product(props) {
  const { product } = props;

  return (
    <div className="product">
      <Link to={product.link} className={product.className}>
        <img className="product-image" src={product.photo} alt="ProductImage" />
        <div className="product-name">
          <h3>{product.name}</h3>
        </div>
      </Link>
      <Button
        href={product.buttonLink}
        type="anchor"
        buttonStyle={product.buttonColor}
      >
        Comprar Online
      </Button>
    </div>
  );
}

export default Product;
