import React from "react";

function Checkbox(props) {
  return (
    <div className="check-container">
      <input type="checkbox" />
      <label htmlFor="checkbox">{props.option}</label>
      <span className="checkmark"></span>
    </div>
  );
}
export default Checkbox;
