import React from "react";

function LeavesAbout() {
  return (
    <svg
      width="187"
      height="425"
      viewBox="0 0 187 425"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M131.318 422.244C153.18 396.147 163.53 374.677 167.149 358.162C171.937 336.335 163.453 323.604 153.859 314.399C146.229 307.083 136.994 302.243 136.994 302.243"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M136.995 302.244C127.191 314.736 121.163 323.584 115.71 332.579C109.564 342.717 103.99 355.472 102.077 363.608C99.8509 373.07 99.6357 380.08 101.081 387.337C102.974 396.833 108.955 404.571 114.631 409.978C121.842 416.846 131.318 422.244 131.318 422.244"
        fill="#2ED065"
      />
      <path
        d="M99.6939 337.132C107.678 304.037 107.407 280.204 103.309 263.802C97.8966 242.12 84.6383 234.487 71.9521 230.506C61.8663 227.343 51.4421 227.112 51.4421 227.112"
        fill="#008E3D"
      />
      <path
        d="M51.4434 227.112C48.2132 242.661 46.7455 253.266 45.8588 263.747C44.8591 275.561 45.5352 289.464 47.4383 297.602C49.6491 307.067 52.5722 313.443 57.0926 319.301C63.0089 326.966 71.8057 331.24 79.293 333.56C88.8054 336.508 99.6939 337.132 99.6939 337.132"
        fill="#2ED065"
      />
      <path
        d="M132.19 272.642C142.248 262.925 154.141 249.385 157.258 234.509C159.139 225.53 156.155 218.502 152.301 213.023C149.774 209.432 146.274 206.459 146.274 206.459"
        fill="#008E3D"
      />
      <path
        d="M146.273 206.459C141.152 211.195 137.387 215.716 134.648 218.9C132.34 221.585 127.221 228.972 124.954 233.614C123.129 237.352 121.431 242.524 120.918 245.491C119.763 252.168 121.656 258.197 123.22 261.355C126.729 268.446 132.19 272.641 132.19 272.641"
        fill="#2ED065"
      />
      <path
        d="M71.4377 372.528C63.2942 361.159 51.6367 347.416 37.3754 342.159C28.7684 338.986 21.3791 340.911 15.3964 343.923C11.4745 345.899 8.02264 348.927 8.02264 348.927"
        fill="#008E3D"
      />
      <path
        d="M8.02164 348.928C11.9592 354.686 15.8814 359.071 18.6317 362.245C20.9506 364.921 27.5099 371.065 31.7715 373.985C35.2031 376.337 40.0714 378.772 42.9311 379.713C49.3684 381.831 55.6085 380.839 58.9615 379.753C66.489 377.318 71.4366 372.528 71.4366 372.528"
        fill="#2ED065"
      />
      <path
        d="M89.6537 216.354C96.6518 204.246 104.364 187.958 103.27 172.798C102.611 163.649 97.8092 157.711 92.5975 153.504C89.1804 150.746 84.9977 148.85 84.9977 148.85"
        fill="#008E3D"
      />
      <path
        d="M84.9963 148.85C81.3754 154.811 78.9986 160.193 77.2411 164.008C75.76 167.224 72.8686 175.733 71.9657 180.82C71.2389 184.916 71.0288 190.355 71.3506 193.349C72.0764 200.087 75.5541 205.362 77.9261 207.969C83.2493 213.822 89.6534 216.353 89.6534 216.353"
        fill="#2ED065"
      />
      <path
        d="M43.6375 205.366C42.8938 191.401 40.4666 173.543 31.2769 161.437C25.731 154.13 18.4671 151.775 11.8036 151.094C7.43505 150.647 2.89575 151.342 2.89575 151.342"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M2.89437 151.342C3.11383 158.314 4.05919 164.121 4.66843 168.276C5.18258 171.78 7.40375 180.488 9.4232 185.243C11.0496 189.072 13.842 193.745 15.7453 196.077C20.0307 201.327 25.8238 203.85 29.2339 204.739C36.8888 206.739 43.6367 205.365 43.6367 205.365"
        fill="#2ED065"
      />
      <path
        d="M58.7434 142.042C61.2994 128.292 63.133 110.364 57.0427 96.4384C53.3675 88.0336 46.8596 84.0391 40.5424 81.8122C36.4007 80.3527 31.8253 79.9619 31.8253 79.9619"
        fill="#008E3D"
      />
      <path
        d="M31.8238 79.9619C30.4002 86.7903 29.9557 92.6566 29.5722 96.8392C29.2494 100.365 29.3638 109.352 30.2102 114.448C30.8922 118.552 32.5094 123.749 33.8118 126.464C36.7447 132.573 41.7836 136.385 44.8895 138.051C51.861 141.792 58.7427 142.041 58.7427 142.041"
        fill="#2ED065"
      />
      <path
        d="M66.8148 68.6197C75.974 58.0518 86.6237 43.5134 88.4136 28.4198C89.4943 19.3104 85.9006 12.5729 81.5774 7.45663C78.7428 4.10277 74.9934 1.45142 74.9934 1.45142"
        fill="#2ED065"
        fillOpacity="0.7"
      />
      <path
        d="M74.9921 1.45074C70.3103 6.6212 66.9597 11.457 64.5133 14.8712C62.4514 17.7496 58.0047 25.5597 56.1572 30.3843C54.6698 34.2693 53.4361 39.571 53.1866 42.5713C52.6267 49.3249 55.0454 55.1621 56.8822 58.1699C61.0041 64.923 66.8147 68.6187 66.8147 68.6187"
        fill="#2ED065"
      />
    </svg>
  );
}

export default LeavesAbout;
