import React from "react";

function AboutSection(props) {
  const { backgroundColor, title, subtitle, text } = props;

  return (
    <section className={`about-section ${backgroundColor}`}>
      <h2 className="white-text">{title}</h2>
      <p className="large-text white-text">{subtitle}</p>
      <p className="medium-text white-text">{text}</p>
    </section>
  );
}

export default AboutSection;
