import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div className="sidebar">
      <p className="normal-text grey-text mb-20">Os nossos produtos</p>
      <Link to="/fig" className="fig-sidebar-link">
        <div className="sidebar-product-name mb-20">Figo Pingo de Mel</div>
      </Link>
      <Link to="/blueberry" className="blueberry-sidebar-link">
        <div className="sidebar-product-name mb-20">Mirtilo</div>
      </Link>
      <Link to="/yellowFig" className="fig-sidebar-link">
        <div className="sidebar-product-name mb-20">Figo da Índia Amarelo</div>
      </Link>
      <Link to="/redFig" className="blueberry-sidebar-link">
        <div className="sidebar-product-name">Figo da Índia Vermelho</div>
      </Link>
    </div>
  );
}

export default Sidebar;
