import React from "react";

import Breadcrumb from "../components/breadcrumb/breadcrumb";
import Sidebar from "../components/sidebar/sidebar";
import HorizontalMenu from "../components/sidebar/horizontalMenu";
import DetailedProduct from "../components/product/detailedProduct";
import LeavesBlueberry from "../components/leaves/leavesBlueberry";
import CardIngredients from "../components/card/cardIngredients";
import CardNutritional from "../components/card/cardNutritional";
import SuggestedProductsHeader from "../components/product/suggestedProductsHeader";
import DiscoverProduct from "../components/product/discoverProduct";

//import product's images
import blueberry from "../images/products/blueberry.png";
import grapefruit from "../images/products/toranja.png";

function Blueberry() {
  const suggestions = [
    {
      name: "Doce de Physalis com Toranja",
      photo: grapefruit,
      link: "https://www.physalina.pt/toranja",
      className: "physalina-link",
    },
  ];

  return (
    <div className="product-details-page">
      <div className="horizontal-menu">
        <HorizontalMenu />
      </div>
      <div className="breadcrumb-container">
        <Breadcrumb home="Home" section="Compotas" name="Mirtilo"></Breadcrumb>
      </div>
      <div className="product-details-grid">
        <div className="sidebar-menu padding-top30 padding-left50">
          <Sidebar />
        </div>
        <DetailedProduct
          photo={blueberry}
          name="Mirtilo"
          color="light-blue-text"
          category="Compotas"
          description="De sabor único, suave e aveludado, é uma compota para ser usada nas mais diversas utilizações, realçando-se quando na companhia de queijos fresco e requeijões ou a completar as mais diversas sobremesas. Também perfeita para usar em torradas."
          weight="240g"
          link="/contacts"
          buttonColor="btn--blueberry"
        ></DetailedProduct>
        <div className="details">
          <h2 className="dark-blue-text ml-10">Detalhes</h2>
          <div className="cards">
            <div className="card-ingridients-container">
              <CardIngredients
                firstIngredient="Bagas de Mirtilo (>60%)"
                extraIngredient="Pectina de frutas"
              ></CardIngredients>
            </div>
            <div className="card-nutritional-container">
              <CardNutritional
                energy="1004 kj / 240 kcal"
                lipids="0.14 g"
                saturados="0.00 g"
                hydrates="58.33 g"
                sugars="44.25 g"
                fiber="3.56 g"
                proteins="1.28 g"
                salt="0.26 g"
              ></CardNutritional>
            </div>
          </div>
        </div>
        <div
          className="leaves-wrapper left-leaves"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <LeavesBlueberry />
        </div>
        <div
          className="leaves-wrapper right-leaves"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <LeavesBlueberry />
        </div>
        <div
          className="suggestions-heading"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-easing="ease-in"
        >
          <SuggestedProductsHeader
            question="Não resiste a um bom doce de fruta?"
            backgroundColor="background-light-blue"
            answer="Ideal para si!"
            textColor="white-text"
          ></SuggestedProductsHeader>
        </div>
        <div className="discover-section-grid">
          {suggestions.map((discoverProduct, idx) => (
            <DiscoverProduct key={idx} discoverProduct={discoverProduct} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blueberry;
