import React, { useEffect } from "react";
import "./App.scss";

import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";

import AOS from "aos";
import "aos/dist/aos.css";

import Topnav from "./components/topnav/topnav";
import LandingPage from "./pages/landingPage";
import ProductList from "./pages/productList";
import Contacts from "./pages/contacts";
import About from "./pages/about";
import Fig from "./pages/fig";
import Blueberry from "./pages/blueberry";
import YellowFig from "./pages/yellowFig";
import RedFig from "./pages/redFig";
import SearchResults from "./pages/searchResults";
import Footer from "./components/footer/footer";
import TermsConditions from "./pages/termsConditions";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 1000,
    });
  }, []);
  return (
    <Router>
      <ScrollMemory />
      <Topnav />
      <Switch>
        <Redirect exact from="/" to="home" />
        <Route path="/home">
          <LandingPage />
        </Route>
        <Route path="/jams">
          <ProductList />
        </Route>
        <Route path="/contacts">
          <Contacts />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/fig">
          <Fig />
        </Route>
        <Route path="/blueberry">
          <Blueberry />
        </Route>
        <Route path="/yellowFig">
          <YellowFig />
        </Route>
        <Route path="/redFig">
          <RedFig />
        </Route>
        <Route path="/termsConditions">
          <TermsConditions />
          </Route>
        <Route path="/searchResults">
          <SearchResults />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
