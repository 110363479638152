import React from "react";
import { Link } from "react-router-dom";

import Button from "../components/button/button";
import LandingHeader from "../components/landingHeader/landingHeader";
import CardProduct from "../components/card/cardProduct";
import Flavours from "../components/product/flavours";
//import Input from "../components/input/input";
import LeavesLeftSide from "../components/leaves/leavesLandingLeft";
import LeavesRightSide from "../components/leaves/leavesLandingRight";
//import LeavesNewsletter from "../components/leaves/leavesNewsletter";

import yellow from "../images/products/yellow.png";
import red from "../images/products/red.png";
//import pumpkin from "../images/products/toranja.png";

function LandingPage() {
  const newestProducts = [
    {
      link: "/yellowFig",
      name: "Figo da Índia Amarelo",
      image: yellow,
      alt: "Figo da Índia Amarelo",
      category: "Doce Extra",
      text: "Usado nas mais diversas utilizações, realça-se com queijos frescos e requeijões.",
    },
    {
      link: "/redFig",
      name: "Figo da Índia Vermelho",
      image: red,
      alt: "Figo da Índia Vermelho",
      category: "Doce Extra",
      text: "Acompanha bem variados queijos ou uma simples torrada. ",
    },
  ];

  /*const soon = [
    {
      link: "/pumpkin",
      name: "Abóbora",
      image: pumpkin,
      alt: "Abóbora",
      category: "Compotas",
      text: "Perfeito para quem adora sabores tradicionais",
    },
  ];*/

  return (
    <main>
      <section className="landing-page">
        <div className="landing-leaves-wrapper">
          <div className="leaves-left-side">
            <LeavesLeftSide />
          </div>
          <div className="leaves-right-side">
            <LeavesRightSide />
          </div>
        </div>
        <div className="introduction-text">
          <p>Da natureza à mesa</p>
          <h2 className="dark-blue-text" data-aos="zoom-in">
            Compotas e doces <br /> 100% nacionais, <br /> de qualidade e deliciosos
          </h2>
          <div className="button-wrapper">
            <Link to="/jams">
              <Button buttonStyle="btn--primary" buttonSize="btn--normal">
                Explorar produtos
              </Button>
            </Link>
            <i className="fa fa-chevron-down chevron"></i>
          </div>
        </div>
      </section>
      <section className="newest-products-section mb-80">
        <LandingHeader type="green" title="Novidades"></LandingHeader>
        <div className="product-cards-grid">
          {newestProducts.map((product, idx) => (
            <CardProduct key={idx} product={product} />
          ))}
        </div>
      </section>
      {/*
      <section className="best-sellers-section">
        <LandingHeader type="blue" title="Brevemente..."></LandingHeader>
        <div className="product-cards-grid">
          {soon.map((product, idx) => (
            <CardProduct key={idx} product={product} />
          ))}
        </div>
      </section>
          */}
      <Flavours />
      {/*<section className="newsletter">
        <h3 className="dark-blue-text">
          Faça parte da nossa <i>newsletter</i>
        </h3>
        <p className="medium-text dark-blue-text newsletter-description">
          Subscreva para ter vantagens nas suas compras <br /> e para estar a
          par das novidades!
        </p>
        <div className="newsletter-input-wrapper">
          <div className="landing-leaves-wrapper">
            <div className="leaves-newsletter-left">
              <LeavesNewsletter />
            </div>
            <div className="leaves-newsletter-right">
              <LeavesNewsletter />
            </div>
          </div>
          <div className="input-wrapper">
            <Input
              labelInput="E-mail"
              placeholder="O meu endereço de e-mail"
              styleName="newsletter-input"
            ></Input>
          </div>
          <Button buttonStyle="btn--primary" buttonSize="btn--normal">
            Subscrever
          </Button>
        </div>
          </section>*/}
    </main>
  );
}

export default LandingPage;
